/*************************
GENERAL & BASIC STYLES
*************************/

*, *::after, *::before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box; }
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-family: 'Audi Type Extended'; vertical-align: baseline; text-decoration: none; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }
ol, ul { list-style: none; }
blockquote, q { quotes: none; }
blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }
table { border-collapse: collapse; border-spacing: 0; }
body { margin: 0; padding: 0 0 0; background: #fff; font-family: 'Audi Type Extended'; font-size: 14px; color: #000; }
*:hover, *:focus { outline: none !important; }
a, button, input, select, textarea { outline: none !important; font-family: 'Audi Type Extended'; }
img { max-width: 100%; }
button, input[type="submit"], a { transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; cursor: pointer; }
.clear:after, .container:after { content: ""; clear: both; display: block; }
img { max-width: 100%; vertical-align: top; }
input, textarea { -webkit-appearance: none; -moz-appearance: none; appearance: none; }
.checkbox input[type="checkbox"] { -webkit-appearance: checkbox; -moz-appearance: checkbox; appearance: checkbox; }
ol, ul { margin-top: 0; margin-bottom: 10px; }
.disabled { display: none !important; }
.container {
    max-width: 1600px !important;
    width: 100% !important;
}


/*************************
Fonts
*************************/


@font-face {
    font-family: 'Audi Type Extended';
    src: url('../fonts/AudiType-ExtendedNormal/AudiType-ExtendedNormal.eot');
    src: url('../fonts/AudiType-ExtendedNormal/AudiType-ExtendedNormal.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AudiType-ExtendedNormal/AudiType-ExtendedNormal.woff2') format('woff2'),
        url('../fonts/AudiType-ExtendedNormal/AudiType-ExtendedNormal.woff') format('woff'),
        url('../fonts/AudiType-ExtendedNormal/AudiType-ExtendedNormal.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Audi Type Extended';
    src: url('../fonts/AudiType-ExtendedBold/AudiType-ExtendedBold.eot');
    src: url('../fonts/AudiType-ExtendedBold/AudiType-ExtendedBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AudiType-ExtendedBold/AudiType-ExtendedBold.woff2') format('woff2'),
        url('../fonts/AudiType-ExtendedBold/AudiType-ExtendedBold.woff') format('woff'),
        url('../fonts/AudiType-ExtendedBold/AudiType-ExtendedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

/*************************
header
*************************/
.home-page-header{background: url(../images/home-banner-bg.png) no-repeat center top;}
.header{ padding: 26px 0;}
.header-inner-box{ display: flex; align-items: center; justify-content: space-between; }
/*.menu ul{ margin: 0; padding: 0; display: flex; align-items: center; }
.menu ul li{ margin-right:70px;}
.menu ul li:last-child{ margin-right:0;}
.menu ul li a{ color: #fff; font-size: 17px; line-height: 22px; position: relative; }
.menu ul li a:hover, .menu ul li a:after{ text-decoration: none; color: #fff; }
.menu ul li a:after{ content: ""; background:#D02B27; width:0; height: 2px; border-radius:100%; position: absolute; margin: 0 auto; left:0; right: 0; bottom: -14px; transition: all 0.3s ease-out 0s; }
.menu ul li a:hover:after, .menu ul li.active a:after{ width: 30px; }
.menu ul li.trial-botton a:after{ display:none; }
.menu ul li.trial-botton a{ height: 50px; line-height: 50px; padding:0 27px; border-radius: 5px; background: #D02B27; color: #fff; display: block;  }
.menu ul li.trial-botton a:hover, .menu ul li.trial-botton a:focus{ background:#1D91CF; color: #fff; text-decoration: none; }
.close-menu{ width: 35px; height: 35px; background: none; padding: 0; border: 1px solid #fff; border-radius: 100%; position: absolute; right: 15px; top: 15px; color: #fff; font-size: 20px; display: none;  }*/
.logo{ margin-bottom: 50px; display: inline-block; }
.toggle-button{ width:63px; background: none; border: none; padding: 0; }
.toggle-button-box{ display: block;}
.trial-botton a{ height: 50px; line-height: 50px; padding:0 27px; border-radius: 5px; background: #D02B27; color: #fff; display: block;}
.trial-botton a:hover, .trial-botton a:focus{ background:#1D91CF; color: #fff; text-decoration: none; }

.menu{ position:fixed; top: 0; left: 0; width: 100%; height:100%; background: #D02B27; z-index: 999; padding:70px 20px 0; transition: all 0.5s ease-out 0s; opacity: 0; visibility: hidden; overflow: hidden;   }
.close-menu{ width: 35px; height: 35px; background: none; padding: 0; border: 1px solid #fff; border-radius: 100%; position: absolute; right: 15px; top: 15px; color: #fff; font-size: 20px; }
.menu.open{ visibility: visible; opacity: 1; overflow: auto; }
.menu ul{ display: block;}
.menu ul li{ margin: 0 0 35px; text-align: center; }
.menu ul li a{ color: #fff; font-size: 17px; line-height: 22px; position: relative; }
.menu ul li a{ font-size: 20px;}
.menu ul li.trial-botton a{ font-size: 16px; background: #1D91CF; width: 250px; margin: 0 auto; line-height: 50px; }
.menu ul li a::after{ display: none; }
.close-menu{ display: block; }

/*************************
Home page  banner
*************************/

.home-banner{ text-align:center; padding:110px 0 0; max-width:1350px; margin: 0 auto; }
.home-banner h1{ font-family: 'Audi Type Extended'; font-weight: normal; color: #fff; font-size: 45px; line-height: 60px; margin-bottom: 19px; }
.home-banner p{ color: #fff; font-size: 22px; line-height: 30px; margin: 0 0 64px; }



/*************************
Home content
*************************/

.home-about{ padding: 12px 0 40px; }
.home-about-title{ text-align: center; margin-bottom:120px; }
.home-about-title h2{ color: #000000; font-size:35px; line-height:47px; font-weight: normal; font-family: 'Audi Type Extended'; margin-bottom:20px; text-transform: uppercase;  }
.home-about-title h2 span{ display: block; color: #A3A3A3; text-transform: uppercase; font-family: 'Audi Type Extended'; font-size: 24px; line-height: 32px; margin-bottom: 18px; }
.home-about-title p{ color: #5C5C5C; font-size:20px; line-height:35px; margin-bottom: 15px;  }
.home-about-title p:last-child{ margin-bottom: 0;}
.home-about-col-box{ display: flex; justify-content: space-between; margin-bottom:80px; }
.home-about-col{ width:24%; border: 1px solid #D9D9D9; border-radius: 2px; text-align: center; position: relative; padding: 44px 15px 40px;  }
.home-about-col:hover, .home-about-col:focus{ background: #CF2A26; }
.home-about-col:hover .home-about-icon, .home-about-col:focus .home-about-icon{ background: #fff;}
.home-about-col:hover  h3, .home-about-col:focus  h3{ color: #fff; }

.home-about-icon{ margin-bottom:20px; width: 119px; height: 119px; border-radius: 100%; box-shadow: 0 0 6px rgba(0,0,0,0.16); text-align: center; margin: 0 auto 20px; display: flex; justify-content: center; align-items: center; }
.home-about-col h3{ color:#000000; font-size:28px; line-height:37px; font-weight: normal; font-family: 'Audi Type Extended';    }

.view-button{ margin: 0 auto; width: 250px; text-align: center; display:block; padding: 0 30px; line-height: 50px; border-radius: 4px; background: #FCEEEE; color: #D02B27; font-size: 18px; }
.view-button img{ vertical-align: top; margin:17px 0 0 10px; }
.view-button:hover, .view-button:focus{ text-decoration: none; background:#EEBABA; color: #D02B27;}

.features-box{ padding:90px 0 220px; background: url(../images/features-bg.png) repeat-y center top; background-size: 100% auto; }
.features-row{ display: flex; justify-content: space-between; margin-bottom: 160px; }
.features-row:last-child{ margin-bottom: 0;}
.features-col{ width: 48%;}
.features-col h3{ font-weight: normal; font-family: 'Audi Type Extended'; color: #141414; font-size: 40px; line-height: 57px; margin-bottom: 29px; }
.features-col p{ color: #5C5C5C; font-size: 20px; line-height: 33px; }
.features-list{ margin:45px 0 0; padding: 0;}
.features-list li{ position: relative; padding-left: 120px; margin-bottom: 55px; }
.features-list li:last-child{ margin-bottom: 0;}
.features-list li span{ position: absolute; left: 0; top: 0; }
.features-list li h4{ color: #141414; font-weight: normal; font-family: 'Audi Type Extended'; font-size: 28px; line-height:37px; margin-bottom:22px; }

.app-box{ background: url(../images/app-bg.jpg) no-repeat; background-size: cover; }
.app-box .container{ position: relative; height:515px;  display: flex; align-items: center;}
.mobile-box{ position: absolute; right: 0; top:-120px; }
.app-box h2{ color: #fff; font-weight: normal; font-family: 'Audi Type Extended'; font-size: 40px; line-height: 53px; margin-bottom: 18px; }
.app-box p{ color: #fff; font-size: 20px; line-height: 33px; margin-bottom: 35px;  }
.app-button-row{ display: flex; }
.app-button-row a{ display: block; margin-right: 20px; }
.app-button-row a:last-child{ margin-right: 0;}

.popular-box{ padding: 200px 0 110px; }
.popular-col-box{ display: flex; flex-wrap: wrap; }
.popular-col{ width: 33.333%; margin-bottom: 70px; }
.popular-col h3{ color: #141414; font-size: 28px; line-height: 37px; margin: 37px 0 22px;  font-weight: normal; font-family: 'Audi Type Extended'; }
.popular-col p{ color: #5C5C5C; font-size: 20px; line-height: 33px; max-width: 90%; } 

.client-box{ background: url(../images/client-bg.jpg) no-repeat center top; padding: 80px 0 50px;}
.client-box .home-about-title{ text-align: left; color: #fff; margin-bottom: 75px; }
.client-box .home-about-title h2{ color: #FFFFFF; }
.client-box .home-about-title h2 span{ color:rgba(255,255,255,0.7);}
.client-slider-col{ background: #FFFFFF; border-radius: 4px; border:1px solid rgba(0,0,0,0.1); padding: 35px 30px; min-height: 310px; }
.client-col-header{ display: flex; justify-content: space-between; margin-bottom: 27px; }
.client-info{ display: flex; align-items: center; }
.client-image{ width:80px; border-radius: 100%; overflow: hidden; margin-right: 18px; }
.client-info h4{ color: #141414; font-size: 20px; line-height: 32px; font-weight: normal; font-family: 'Audi Type Extended'; }
.client-info p{ color: #D02B27; font-size: 18px; }
.client-rating{ color: #FBC509; font-size: 25px; }
.client-col-content{ color: #5C5C5C; font-size: 20px; line-height: 33px; }
.client-slider-box .tns-controls{ position: absolute; top: -110px; right: 0; } 
.client-slider-box .tns-controls button{ width:45px; height: 45px; background: none; border: 1px solid #fff; border-radius: 4px; color: #fff; line-height: normal; padding: 0; }
.client-slider-box .tns-controls button span{ display: block; line-height: 35px; font-size: 40px;display: none; }
.client-slider-box .tns-controls button:first-child {margin-right: 15px;right: 29px;left: auto;}
.client-slider-box .tns-controls button:hover{ background: #fff; color: #1D91CF; }
.client-slider{display: block;}
.tns-slide-active{width: 770px;margin-right: 30px;}
.subscribe-box{ padding: 40px 0 110px; }
.subscribe-box .home-about-title{ margin-bottom: 80px;}
.subscribe-form{ max-width:1085px; margin: 0 auto; display: flex; }
.subscribe-input{ width:785px; height: 55px; border: 1px solid #C2C2C2; border-radius: 4px 0 0 4px; border-right:none; padding: 0 30px; font-size:20px; color: #000;  }
.subscribe-button{ background: #D02B27; border-radius: 0 4px 4px 0; width: 300px; height: 55px; font-size: 18px; color: #FFFFFF; text-transform: uppercase; border: none;   }

/*************************
Footer
*************************/
.top-footer{ background:#000000; padding:60px 0 30px; }
.top-inner-footer{ display: flex; justify-content: space-between; }
.footer-about{ max-width:510px; color:#939393; font-size: 20px; line-height: 33px; margin: 20px 10px 0px; } /**20px 0 0;**/
.footer-menu{ margin: 0; padding: 0; display: flex; flex-wrap: wrap; max-width: 490px; }
.footer-menu li{ width: 50%; margin-bottom: 12px; }
.footer-menu li a{ color: #939393; font-size: 20px; padding-left: 18px; position: relative;}
.footer-menu li a:after{ content: ""; background: url(../images/footer-arrow.png) no-repeat; width: 8px; height: 14px; position: absolute; left:0; top:7px; }
.footer-menu li a:hover, .footer-menu li a:focus{ text-decoration:none; color: #1D91CF; }
.footer-col-title{ color: #fff; font-size: 18px; line-height:normal; font-weight: normal; font-family: 'Audi Type Extended'; text-transform: uppercase; margin-bottom: 30px; }
.info-text{max-width:340px; color:#939393; font-size: 20px; line-height: 30px; margin:0 0 20px;}
.footer-info ul li{ margin-bottom:20px;}
.footer-info ul li, .footer-info ul li a{  color:#939393; font-size: 20px; line-height:26px;}
.footer-info ul li span{ display: inline-block; width:110px; color: #fff; }
.footer-logo img{ width:280px; }

.copyright-bar{ background:#F6E920; padding:22px 0; }
.copyright-inner-bar{ display: flex; align-items: center; justify-content: space-between; }
.copyright-left{ color: #000000; font-size: 17px; }
.copyright-right ul{ margin: 0; padding: 0; display: flex;margin-top: 20px; }
.copyright-right ul li{ margin-right: 10px;}
.copyright-right ul li:last-child{ margin-right: 0;}
.copyright-right ul li a{ display: flex;align-items: center;justify-content: center;background: #000000;width: 80px;height: 29px;border-radius: 4px;color: #fff;font-size: 12px;
    font-weight: bold;}
.copyright-right ul li a:hover, .copyright-right ul li a:focus{ text-decoration: none; background:#1D91CF; }

/*************************
FAQ
*************************/

.header.inner-header{ padding: 26px 0;  background-repeat: no-repeat; background-size: cover; background-position: center center; }
.header.inner-header .logo{margin-bottom: 70px !important;}
.header.inner-header .logo img{ width:100%; }
.header.inner-header .toggle-button span{ width: 100%; height: 3px; margin-bottom: 5px; background: #fff; display: block; }
.header.inner-header .toggle-button span:last-child{ margin-bottom: 0; }

.inner-banner-about{background: url(../images/about-bg.jpg);}
.inner-banner-price{background: url(../images/price-banner.jpg);}
.inner-banner-faq{background: url(../images/faq-banner.jpg);}
.inner-banner-contact{background: url(../images/contact-banner.jpg);}


.inner-banner{ height:350px; background-repeat: no-repeat; background-size: cover; display: flex; align-items: center; justify-content: center; text-align: center; }
.inner-banner h1{ color: #fff; font-weight: normal; font-family: 'Audi Type Extended'; font-size: 35px; line-height: 47px; margin-bottom: 12px;  }
.inner-banner p{ color: #fff; font-size: 17px;}
.faq-box{ padding: 90px 0 110px;}
.faq-box .Collapsible{ margin: 0; }
.panel-group .panel + .panel{ margin-top: 0;}
.faq-box .Collapsible{ margin-bottom:25px; border-radius: 0; border: none; box-shadow: none;}
.faq-box .panel-default > .panel-heading{ padding: 0; background: none; border: none; border-radius: 0; }
.faq-box .Collapsible > .Collapsible__trigger { padding: 25px 35px; background: #D02B27; display: block; color: #fff; font-size: 22px; font-family: 'Audi Type Extended'; border-radius: 5px; position: relative; }
.faq-box .Collapsible > .Collapsible__trigger:after{ content:"\f107"; position: absolute; right:20px; top: 15px; color:#fff;font-family: 'FontAwesome'; font-size: 40px; transform: rotate(180deg); }
.faq-box .Collapsible > .Collapsible__trigger.is-closed{ background: #EBEBEB; color: #000000;}
.faq-box .Collapsible > .Collapsible__trigger.is-closed:after{ color:#000;  transform:none;}
..faq-box .Collapsible > .Collapsible__trigger:hover, .faq-box .Collapsible > .Collapsible__trigger:focus{ text-decoration: none;}
.faq-box .panel-body{ padding:30px; border: none !important; background: #F5F5F5; border-radius: 0 0 4px 4px; box-shadow: 0 2px 20px rgba(0,0,0,0.1); color: #5C5C5C; font-size: 20px; line-height: 32px; }


/*************************
Contact
*************************/

.contact-box{ padding: 80px 0 110px;}
.contact-inner-box{ display: flex; justify-content: space-between; }
.map-box iframe{ width: 100%; vertical-align:top; }
/* .contact-left{ max-width: 768px; background: url(../images/contact-bg.png) no-repeat center bottom; background-size: 90% auto; } */
.contact-left h2{ color: #000000; font-size: 32px; line-height: 43px; font-weight: normal; font-family: 'Audi Type Extended'; margin-bottom: 33px;   }
.contact-left p{ color:#5C5C5C; font-size: 19px; line-height: 38px; margin-bottom: 50px; }
.contact-left p:last-child{ margin-bottom: 0;}
.contact-right{ width:768px; box-shadow: 0 3px 20px rgba(0,0,0,0.1); padding: 30px 40px; background: #ffff48; }
.field-row{ margin-bottom:25px;}
.field-row > label{ display: block; color: #5C5C5C; font-size: 20px; line-height: 27px; font-weight: normal; margin-bottom: 13px; }
.field-row > input{ background: #fff; border: 1px solid rgba(6,25,42,0.16); border-radius: 4px; color: #000; padding: 0 20px; font-size: 16px; height: 55px; width: 100%; }
.field-row > textarea{ background: #fff; border: 1px solid rgba(6,25,42,0.16); resize: none; border-radius: 4px; color: #000; padding:20px; font-size: 16px; height:175px; width: 100%; }
.captcha-row{ margin-bottom: 30px;}
.field-button{ width:257px; height: 55px; border-radius: 4px;     background-color: #5cb85c;
    border-color: #4cae4c; border:none ; font-size: 18px; color: #FFFFFF; font-family: 'Audi Type Extended'; }
.field-button:hover, .field-button:focus{ background: #1D91CF; color: #fff; text-decoration: none; }



/*************************
About Us
*************************/

.shop-box{ padding:90px 0 70px;}
.shop-box .home-about-title{ margin-bottom: 0;}
.about-feature-box{ padding:70px 0 110px;}
.owl-carousel .owl-item .popular-col img{ width: auto;}
.tns-nav{ text-align: center;}
.tns-nav button { border: none; padding: 0; width: 29px; height: 4px; border-radius: 8px; background: #EBEBEB; margin-right: 10px; }
.tns-nav button:last-child{ margin-right: 0 ;}
.tns-nav .tns-nav-active{ width: 40px; background: #D02B27; }
.demonstration-box{ background:#D02B27; padding: 115px 0 10px; }
.demonstration-box .home-about-title h2 span{ color:rgba(255,255,255,0.5) ; }
.demonstration-box .home-about-title h2{ color:#FFFFFF;}
.demonstration-box .home-about-title p{ color:rgba(255,255,255,0.8);}
.demonstration-inner-box{ display: flex; justify-content: space-between; } 
.demonstration-right{ width:840px;}
.demonstration-list{ padding-left:120px; position: relative; margin-bottom:90px;}
.demonstration-list:last-child{ margin-bottom: 0;}
.demonstration-list span{ position: absolute; left:0; top: 0; }
.demonstration-list h3{ color: #fff; font-weight: normal; font-family: 'Audi Type Extended'; font-size: 28px; line-height: 37px; margin-bottom: 20px; }
.demonstration-list p{ color: #fff; font-size: 20px; line-height: 33px; }

.tns-outer{
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0,0,0);
}

.gallery-box{ padding:110px 0;}
.gallery-inner-box{ display: flex; justify-content: space-between; align-items: flex-start; }
.gallery-inner-box img{ vertical-align: top; }
.gallery-left{ max-width:760px; border-radius: 4px; border: 1px solid #D9D9D9; overflow: hidden; }
.gallery-right{ max-width: 760px;}
.gallery-col{ margin-bottom: 40px;}
.gallery-col:last-child{ margin-bottom: 0;}
.tns-controls button{ z-index: 999;border: none;padding: 0!important;font: inherit;position: absolute; top: 50%; left:0; margin-top: -44px; width: 45px; height: 88px; border-radius:0 4px 4px 0; box-shadow: 2px 0 6px rgba(0,0,0,0.2); background: #fff; color: #5C5C5C; font-size:60px; font-weight: normal;  }

.tns-controls button:last-child {
    left: auto;
    right: 0;
    border-radius: 0px 4px 4px 0px;
}

.client-box .tns-outer{overflow: inherit;}
.tns-controls button:first-child:hover, .tns-controls button:last-child:hover{ background:#D02B27; color: #fff; }

.subscription-box{ padding:45px 0; background: #1D91CF; }
.subscription-inner-box{ display: flex; align-items: center; justify-content: space-between; }
.subscription-left h3{ color:#FFFFFF; font-size: 40px; line-height: 53px; margin-bottom: 17px; font-weight: normal; font-family: 'Audi Type Extended'; }
.subscription-left p{ color: #FFFFFF; font-size: 20px; line-height: 33px; }
.subscription-right{ display: flex; }
.subscription-right a{ width: 255px; height: 56px; display: block; border-radius: 4px; border: 1px solid #fff; text-align: center; line-height: 54px; font-size: 18px;  }
.demo-button{ background: #fff; margin-right: 30px; color: #1D91CF;  }
.demo-button:hover, .demo-button:focus{ text-decoration: none; background: none; color: #fff; text-transform: none; }
.sign-button{ color: #fff; }
.sign-button:hover, .sign-button:focus{ text-decoration: none; color: #1D91CF; background: #fff; }


/*************************
Priceing
*************************/
.price-box{ padding: 90px 0 110px;}
.price-col-box{ display: flex; justify-content: space-between; }
.price-col{ width: 510px; border: 1px solid #C2C2C2; border-radius: 4px; padding:62px 50px 90px; }
.price-col:nth-child(2){ box-shadow: 0 0 60px rgba(0,0,0,0.12); }
.price-col-title{ text-align: center; margin-bottom:34px; }
.price-col-title h3{ font-size: 22px; line-height: normal; margin-bottom: 10px; font-weight: normal; font-family: 'Audi Type Extended'; }
.price-col-title p{ font-size:20px;  }
.price{ font-size: 58px; line-height: 78px; font-weight: normal; font-family: 'Audi Type Extended'; text-align: center; margin-bottom: 65px; }
.price span{ font-family: 'Audi Type Extended'; font-size: 30%; }
.price-feature{ margin-bottom: 90px;}
.price-feature ul{ margin: 0; padding: 0;}
.price-feature ul li{ font-size: 20px; margin-bottom:20px; padding-left: 37px; position: relative;  }
.price-feature ul li:last-child{ margin-bottom: 0;}

.price-button{ display: block; margin: 0 auto; background: #EBEBEB; border-radius: 4px; width: 255px; height: 55px; text-align: center; line-height: 55px; color: #000000; font-size: 18px;  }
/* .price-col:nth-child(2) .price-button{ background: #D02B27; color: #fff; } */

.credit-text{ text-align: center; color: #5C5C5C; font-size: 18px; margin-top: 60px; }
.mand{ color: #D02B27; }

.price-col-1{
    background: #ff1111;
    color: #FFFFFF !important;
    border-color: #D02B27;
}
.price-col-1 > .price-feature ul li:after{ content: ""; width: 25px; height: 25px; position: absolute; left: 0; top: 3px; background: url(../images/li-white.png) no-repeat;  }
.price-col-1 > .price-button:hover, .price-button:focus{ background: #EBEBEB; color: #000000; text-decoration:none; }
.price-col-1 > .price-button{ background: #ad1511; color: #fff; text-decoration:none; }
.price-col-2{
    background: #ffff4b;
    color: #000000 !important;
    border-color: #ffff4b;
}
.price-col-2 > .price-feature ul li:after{ content: ""; width: 25px; height: 25px; position: absolute; left: 0; top: 3px; background: url(../images/li-black.png) no-repeat;  }
.price-col-2 > .price-button:hover, .price-button:focus{ background: #EBEBEB; color: #000000; text-decoration:none; }
.price-col-2 > .price-button{ background: #d6d600; color: #000000; text-decoration:none; }
.price-col-3{
    background: #007c00;
    color: #FFFFFF !important;
    border-color: #007c00;
}
.price-col-3 > .price-button{ background: #004800; color: #fff; text-decoration:none; }
.price-col-3 > .price-feature ul li:after{ content: ""; width: 25px; height: 25px; position: absolute; left: 0; top: 3px; background: url(../images/li-white.png) no-repeat;  }
.price-col-3 > .price-button:hover, .price-button:focus{ background: #EBEBEB; color: #000000; text-decoration:none; }
#load{
    width:100%;
    height:100%;
    position:fixed;
    z-index:9999;
    background:url(../images/loader.svg) no-repeat center center rgba(0,0,0,0.25)
}

.inlineBlock{
    background: #101215 !important;
}

/* 14-10-2021 */


.copyright-right i.fa.fa-twitter,
.copyright-right i.fa.fa-linkedin {
    font-size: 17px;
    margin-right: 5px;
}

.copyright-right i.fa.fa-envelope {
    font-size: 14px;margin-right: 5px;}

.copyright-right i.fa.fa-linkedin {
    margin-top: -3px;
}

.copyright-right ul li button a.tw {
    background: #1D91CF;
}
.copyright-right ul li button a.ld {
    background: #0a66c2;
}

.copyright-right ul li button a.em{ background: #0a66c2;}

.tns-controls button {
    font-size: 0px;
}
.tns-controls button:first-child:after{
    content: "\f104";
}
.tns-controls button:last-child:after {
    content: "\f105";}

.tns-controls button:first-child:after,.tns-controls button:last-child:after
 {
    font-family: 'FontAwesome';
    font-size: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
}

@media only screen and (max-width: 480px) {
    .home-page-header {
        background: url(../images/home-banner-bg.png) no-repeat;
        background-position: center;
        background-size: cover;
    }
}
.flashMsg{color: rgb(178, 236, 178);}