@media only screen and (max-width:1600px){    

	.container{ width: 1198px;}
	.menu ul li { margin-right: 40px;	}
	.home-banner{ max-width: 1170px; }
	.home-about-title p br{ display: none; }
	.home-about-col::after{ z-index: -1; }
	.home-about-col h3{ font-size: 24px;}
	.features-col h3{ font-size: 30px; line-height: 46px;}
	.features-col p{ font-size: 18px; line-height: 30px; }
	.features-list li h4{ font-size: 24px; line-height: 27px; }
	.contact-left, .contact-right{ width: 550px; max-width: none; }
	.demonstration-box{ padding:110px 0 ; }
	.gallery-left, .gallery-right{ width: 570px; }
	.price-col{ width: 380px;}
	.price{ font-size: 50px; line-height: 68px;  }
    

}

@media only screen and (max-width:1199px){

	.container{ width: 991px;}
	.logo img{ width: 190px;}
	.menu ul li a{ font-size: 15px;}
	.menu ul li { margin-right: 30px;	}
	.home-banner h1{ line-height: normal; font-size: 34px; }
	.home-banner p{ line-height: normal; font-size: 18px; }
	.home-about-title h2{ font-size: 28px; line-height: normal; }
	.home-about-title h2 span{ line-height: normal; font-size: 20px; }
	.home-about-title p{ font-size:16px; line-height: 28px; }
	.home-about-col h3 { font-size:16px; line-height: normal; }
	.home-about-col p{ font-size: 14px; line-height: 22px; }
	

	.features-col h3{ font-size: 22px; line-height: normal;	}
	.features-col p{ font-size: 14px; line-height: 22px; }
	.features-list li h4 { font-size: 22px;	line-height: 26px; margin-bottom: 10px; 	}
	.features-list li{ padding-left:80px;}
	.features-list li span{ width: 60px; }
	.app-box h2{ line-height: normal; font-size: 26px; }
	.app-box p{ font-size: 16px; line-height: 26px; width: 90%;  }
	.mobile-box{ top:-60px; width: 250px; right: 15px; }
	.app-box .container{ height: 375px;}
	.popular-col h3{ font-size: 20px; line-height: normal; margin: 20px 0 10px; }
	.popular-col p{ font-size: 14px; line-height: 22px; }
	.client-rating{  font-size: 16px;}
	.client-info h4{ font-size: 15px; line-height: normal; }
	.client-info p{ font-size: 13px;}
	.client-col-content{ font-size: 14px; line-height: 22px; }
	.client-image{ width: 60px; margin-right:10px; }

	.demonstration-right { width: 600px; }
	.demonstration-list h3{ font-size: 22px; margin-bottom: 10px; }
	.demonstration-list p{ font-size: 16px; line-height: 28px; }
	.demonstration-list span{ width:60px; }
	.demonstration-list{ padding-left: 80px;}

	.gallery-left, .gallery-right{ width: 470px; }
	.subscription-inner-box{ display: block; text-align: center; }
	.subscription-left{ margin-bottom: 30px;}
	.subscription-right{justify-content: center; }
	.price-col{ width: 315px; padding: 50px 20px 70px; }
	.price-feature ul li{ font-size: 14px;}
	
	
    

	
}



@media only screen and (max-width:991px){
	
	.container{ width: 768px;}
	/* .logo img{ width: 150px;} */
	.toggle-button-box{ display: block;}
	.menu{ position:fixed; top: 0; left: 0; width: 100%; height:100%; background: #D02B27; z-index: 999; padding:70px 20px 0; transition: all 0.5s ease-out 0s; opacity: 0; visibility: hidden; overflow: hidden;   }
	.menu.open{ visibility: visible; opacity: 1; overflow: auto; }
	.menu ul{ display: block;}
	.menu ul li{ margin: 0 0 35px; text-align: center; }
	.menu ul li a{ font-size: 20px;}
	.menu ul li.trial-botton a{ font-size: 16px; background: #1D91CF; width: 250px; margin: 0 auto; line-height: 40px; }
	.menu ul li a::after{ display: none; }
	.close-menu{ display: block; }
	/* .home-about-icon{ margin-bottom: 30px;} */
	/* .home-about-icon img{width: 80px; } */
	/* .home-about-col { width: 31%; 	} */
	/* .home-about-col::after{ display:none;}
	.home-about-col h3{ font-size: 16px;}
	.home-about-col p {	font-size: 12px; line-height: 20px; 	} */
	.features-list li h4{ font-size: 20px;}
	.features-col h3 br{ display: none;}
	.mobile-box{ display: none;}
	.app-button-row a img{ height: 60px;}
	.popular-box{ padding: 100px 0;}
	.popular-col h3{ font-size: 18px;}
	.top-inner-footer{ flex-wrap: wrap;}
	.footer-col:first-child{ width: 100%; margin-bottom:40px; }
	.footer-about{ max-width: 100%;}
	.footer-col{ width: 50%;}

	.inner-header .menu ul li a{ color: #fff;}
	.contact-inner-box{ display: block; }
	.contact-left, .contact-right{ width:100%; max-width: none; }
	.contact-left{ margin-bottom:50px; background: none; }
	.gallery-inner-box{ display: block;}
	.gallery-left{ margin-bottom: 40px;}

	.gallery-left, .gallery-right{ width:100%; }
	.price-col-box{ display: block;}
	.price-col{ width:100%; margin: 0 0 30px; }
	.price-col:last-child{ margin: 0;}
	

	

}


@media only screen and (max-width:767px){

	.trial-botton a{ font-size: 12px; height:40px; line-height:40px; padding: 0 15px;}
	.toggle-button{ width: 40px;}
	.home-banner .logo img{ display: block;}

	.header.inner-header .logo img { width: 150px; }
	.container{ width:100%;}
	.home-banner{ padding:50px 15px 50px;}
	.home-banner h1{ font-size:20px; }
	.home-banner h1 br{ display: none;}
	.home-banner p{ font-size: 14px; line-height: 22px; margin: 0; }
	.home-banner img{ display: none;}

	.home-about{ padding: 30px 0;}
	.home-about-title h2 span{ margin-bottom:10px; font-size: 17px; }
	.home-about-title h2{ font-size: 20px; margin-bottom: 15px; }
	.home-about-title p { font-size: 13px; line-height: 22px; }
	.home-about-title{ margin-bottom: 50px;}
	/* .home-about-icon{ display: none;} */
	.home-about-col-box{ display: block; margin-bottom: 40px;}
	.home-about-col{ margin-bottom:20px; width: 100%; }
	.home-about-col:last-child{ margin-bottom: 0; }  
	.home-about-col h3{ font-size: 16px;}
	.view-button{width: 220px; font-size: 16px; }
	
	.features-box{ padding: 30px 0;}
	.features-col-image{ display: none;}
	.features-col{ width: 100%;}
	.features-col h3{ font-size:18px; margin-bottom: 20px;  }
	.features-list li h4{ font-size: 16px;}
	.features-row{ margin-bottom: 50px;}
	.app-box{ padding: 30px 0;}
	.app-box .container{ height: auto;}
	.app-box h2{ font-size: 20px;}
	.app-box p{ line-height: 22px; font-size: 14px; }
	.app-button-row a img { height: 40px; }

	.popular-box{ padding:30px 0;}
	.popular-col{ width: 100%; margin: 0 0 30px; text-align: center; }
	.popular-col > img{ display: none;}
	.popular-col h3{ font-size: 16px; margin: 0 0 15px; }

	.client-box{ padding:30px 0 40px;}
	.client-slider-box .owl-nav { top: -60px; right: 0; left: 0; margin: auto;		text-align: center; 	}
	.client-slider-col{ padding: 20px 15px;}
	.client-col-header{ display: block;}
	.client-info{ margin-bottom: 10px;}
	.subscribe-box{ padding: 20px 0 50px;}
	.subscribe-form{ max-width: 100%; display: block;}
	.subscribe-input{ width: 100%; margin: 0 0 10px; height: 40px; border: 1px solid #C2C2C2; border-radius: 4px; padding: 0 10px; font-size: 14px; text-align: center; }
	.subscribe-button{ width: 100%; height: 40px; border-radius: 4px; font-size: 16px; }
	.subscribe-box .home-about-title { margin-bottom: 30px;	}

	.top-footer{ padding:30px 0 10px;}
	.footer-about, .info-text, .footer-info ul li, .footer-info ul li a{ font-size: 14px; line-height: 22px;}
	.footer-col{ width: 100%; margin-bottom: 30px; }
	.footer-col:last-child{ margin-bottom: 0; }
	.footer-menu li{ width: 100%;}
	.footer-menu li a{ font-size: 14px;}
	.footer-menu li a::after{ top: 4px;}
	.footer-col-title{ margin-bottom: 15px;}
	.footer-info ul li span{ width: 100px;}
	.copyright-inner-bar{ text-align: center; display:block;  }
	.copyright-left{ font-size: 14px; margin-bottom: 15px; }
	.copyright-right ul{ justify-content: center;}

	.inner-banner{ height: 250px;}
	.inner-banner h1{ font-size: 24px; line-height: 36px; margin-bottom: 7px; }
	.inner-banner p{ font-size: 14px;}
	.faq-box{ padding: 20px 0 ;}
	.faq-box .panel-default > .panel-heading .panel-title a{ font-size: 14px; padding: 10px 30px 10px 15px; }
	.faq-box .panel-default > .panel-heading .panel-title a::after{right: 14px; 		top: 14px;font-size: 24px;}
	.faq-box .panel-body{ padding: 15px; font-size: 12px; line-height: 20px; }
	.faq-box .panel-group .panel{ margin-bottom: 10px;}

	.contact-box{ padding: 30px 0 50px; }
	.contact-left h2{ font-size: 26px; line-height: normal; margin-bottom: 20px; }
	.contact-left p{ font-size: 14px; line-height: 28px; margin-bottom: 10px; }
	.contact-right{ padding:30px 20px;}
	.field-row > label{ font-size: 14px; margin-bottom: 5px; }
	.field-row > input{ height: 45px;}

	.shop-box, .about-feature-box, .demonstration-box, .gallery-box{ padding: 30px 0; }
	.owl-carousel .owl-item .popular-col img{ margin: 0 auto 15px; width:50px; }

	.demonstration-inner-box{ display: block;}
	.demonstration-left{ display: none;}
	.demonstration-right{ width: 100%;}
	.demonstration-list{ margin-bottom:40px; padding-left:60px; }
	.demonstration-list span{ width: 45px;}
	.demonstration-list h3{ font-size: 18px;}
	.demonstration-list p{ font-size: 14px; line-height: 22px; }

	.gallery-col{ margin-bottom: 20px;}
	.subscription-left h3{ font-size: 28px;	line-height: 43px; margin-bottom: 8px; }
	.subscription-left p{ font-size: 14px; line-height: 22px;}
	.demo-button{ margin-right: 10px;}
	.subscription-right a{ width: auto; padding: 0 15px; font-size: 14px; height: 40px; line-height: 38px; }

	.price-box{ padding: 30px 0;}
	.price-button{ width: 220px;}
	.price { font-size: 40px; line-height: 58px; margin-bottom: 30px; }
	.credit-text{ font-size: 12px; margin-top: 30px;}
	.price-feature { margin-bottom: 60px; }
	
	
}


